var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "value": _vm.active,
      "temporary": "",
      "touchless": "",
      "right": !_vm.$vuetify.rtl,
      "width": _vm.$vuetify.breakpoint.smAndUp ? 480 : '100%',
      "app": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('aside', val);
      }
    }
  }, [_c('h3', {
    staticClass: "mb-4 mt-4 pl-4 pr-4"
  }, [_vm._v(" Move distributors into this new deployment. ")]), _c('migration-move-in-form', {
    attrs: {
      "loading": _vm.loading,
      "errors": _vm.errors
    },
    on: {
      "submit": _vm.handleSubmit
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }