<template>
  <div id="site-list">
    <v-row class="my-0 d-flex">
      <v-col
        cols="12"
      >
        <v-btn
          v-if="!tableItems.length"
          color="success"
          class="mr-4"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>

        <!-- v-if="!tableItems.length" -->
        <v-btn
          class="mr-4"
          @click="setAside(null, 'move-in')"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiArrowAll }}
          </v-icon>
          Move distributors in
        </v-btn>
      </v-col>
      <v-col cols="12">
        Make sure you have read: <a
          href="https://mixcare-health.atlassian.net/wiki/spaces/MA/pages/229441537"
          target="_blank"
        >See Migration Docs</a>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <template #[`item.code`]="{ item }">
          <b>{{ item.code }}</b>
        </template>

        <template #[`item.action`]="{ item }">
          {{ item.action }}
        </template>

        <template #[`item.distributors`]="{ item }">
          <div class="text-wrap dict-value">
            <b>{{ t(item.value) }}</b>
          </div>
          <ul>
            <li
              v-for="distributor in item.distributors"
              :key="distributor.id"
            >
              <span class="text-wrap">{{ distributor?.business_name }}</span>
            </li>
          </ul>
        </template>

        <!-- notes -->
        <template #[`item.notes`]="{ item }">
          <span class="text-wrap">{{ item.notes }}</span>
        </template>

        <!-- fulfillable-->
        <template #[`item.fulfillable`]="{ item }">
          <span class="text-no-wrap">{{ item.fulfillable ? 'Yes' : 'No' }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-if="!item.fulfillable"
                  link
                  @click="deleteMigrationScheme(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-else
                  link
                  @click="fulfillMigrationScheme(item)"
                >
                  <v-list-item-title>
                    <span>Fulfill</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <migration-scheme-aside
      v-if="aside === 'migration-scheme'"
      v-model="aside"
      :resource="resource"
      @changed="loadData(); aside = false"
    />

    <migration-move-in-aside
      v-if="aside === 'move-in'"
      v-model="aside"
      @changed="loadData(); aside = false"
    />

    <!-- <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        loadCards()
        aside = false
      "
    ></form-aside> -->
  </div>
</template>

<script>
import {
mdiArrowAll,
mdiDeleteOutline, mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'

import { useDistributor } from '@/composables'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import MigrationMoveInAside from '../components/migration-scheme/MigrationMoveInAside.vue'
import MigrationSchemeAside from '../components/migration-scheme/MigrationSchemeAside.vue'
import useMigrationSchemeList from '../composables/useMigrationSchemeList'

export default {
  components: {
    MigrationSchemeAside,
    MigrationMoveInAside,
  },
  setup() {
    const {
      tableItems, tableColumns, tableTotal, options, filters, loading, loadData, deleteMigrationScheme, fulfillMigrationScheme,
    } = useMigrationSchemeList()

    const actions = ['Edit']

    const aside = ref('')
    const resource = ref(null)

    const setAside = (item, type = 'migration-scheme') => {
      aside.value = type
      resource.value = item
    }

    // const categories = ref([])

    // const loadCategories = async () => {
    //   try {
    //     const {
    //       status,
    //       data: {
    //         data: { records },
    //       },
    //     } = await fetchDistributors()

    //     if (status === 200) {
    //       categories.value = records.map(record => {
    //         return { title: record.name.en, value: record.id }
    //       })
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    // loadCategories()

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    const { distributorOptions } = useDistributor()

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
        mdiArrowAll,
      },

      // categories,
      setAside,
      aside,
      resource,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,

      actions,

      loadData,
      deleteMigrationScheme,
      fulfillMigrationScheme,
      singleSelect,
      headerprops,
      showFilter,
      distributorOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.dict-value {
  max-height: 45px;
  overflow: hidden;
}
</style>
