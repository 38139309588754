<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <h3 class="mb-4 mt-4 pl-4 pr-4">
      Move distributors into this new deployment.
    </h3>
    <migration-move-in-form
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors, useNotifySuccess } from '@/composables'
import { moveInMigrationScheme } from '@api/distributor/migration'
import { useRouter } from '@core/utils'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import MigrationMoveInForm from './MigrationMoveInForm.vue'

export default {
  components: {
    MigrationMoveInForm,
  },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])
    const { router } = useRouter()

    const handleSubmit = async form => {
      console.log('form', form)
      loading.value = true
      const request = moveInMigrationScheme({
        ...form,
      })
      await request
        .then(async res => {
          emit('changed', res.data.data)
          useNotifySuccess({ content: 'Move in succeeded' })
          router.push({ name: 'distributors' })
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
