import axios from '@axios'

const destroyMigrationScheme = scheme => axios.delete(`/admin/core/channel-admin/migration-schemes/${scheme.id}`)

const fetchMigrationSchemes = () => axios.get('/admin/core/channel-admin/migration-schemes')

const fulfillMigrationScheme = (scheme, data) => axios.post(`/admin/core/channel-admin/migration-schemes/${scheme.id}/fulfill`, data)

const moveInMigrationScheme = data => axios.post('/admin/core/channel-admin/migration-schemes/move-in', data)

const storeMigrationScheme = data => axios.post('/admin/core/channel-admin/migration-schemes', data)

export { destroyMigrationScheme, fetchMigrationSchemes, fulfillMigrationScheme, moveInMigrationScheme, storeMigrationScheme }
