var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "site-list"
    }
  }, [_c('v-row', {
    staticClass: "my-0 d-flex"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.tableItems.length ? _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.setAside(null);
      }
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('common.list.create')) + " ")], 1) : _vm._e(), _c('v-btn', {
    staticClass: "mr-4",
    on: {
      "click": function click($event) {
        return _vm.setAside(null, 'move-in');
      }
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiArrowAll) + " ")]), _vm._v(" Move distributors in ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Make sure you have read: "), _c('a', {
    attrs: {
      "href": "https://mixcare-health.atlassian.net/wiki/spaces/MA/pages/229441537",
      "target": "_blank"
    }
  }, [_vm._v("See Migration Docs")])])], 1), _c('v-card', [_c('v-data-table', {
    staticClass: "text-no-wrap",
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.tableItems,
      "options": _vm.options,
      "server-items-length": _vm.tableTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" #" + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.code",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('b', [_vm._v(_vm._s(item.code))])];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.action) + " ")];
      }
    }, {
      key: "item.distributors",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-wrap dict-value"
        }, [_c('b', [_vm._v(_vm._s(_vm.t(item.value)))])]), _c('ul', _vm._l(item.distributors, function (distributor) {
          return _c('li', {
            key: distributor.id
          }, [_c('span', {
            staticClass: "text-wrap"
          }, [_vm._v(_vm._s(distributor === null || distributor === void 0 ? void 0 : distributor.business_name))])]);
        }), 0)];
      }
    }, {
      key: "item.notes",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "text-wrap"
        }, [_vm._v(_vm._s(item.notes))])];
      }
    }, {
      key: "item.fulfillable",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.fulfillable ? 'Yes' : 'No'))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on,
                attrs = _ref8.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [!item.fulfillable ? _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteMigrationScheme(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDeleteOutline) + " ")]), _c('span', [_vm._v("Delete")])], 1)], 1) : _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.fulfillMigrationScheme(item);
            }
          }
        }, [_c('v-list-item-title', [_c('span', [_vm._v("Fulfill")])])], 1)], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1), _vm.aside === 'migration-scheme' ? _c('migration-scheme-aside', {
    attrs: {
      "resource": _vm.resource
    },
    on: {
      "changed": function changed($event) {
        _vm.loadData();
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e(), _vm.aside === 'move-in' ? _c('migration-move-in-aside', {
    on: {
      "changed": function changed($event) {
        _vm.loadData();
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }