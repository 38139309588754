<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-text-field
          v-model="form.code"
          label="Code (Code to the migration scheme.)"
          outlined
          class="mt-5"
        />
        <v-autocomplete
          v-model="form.channel_distributor_ids"
          label="Select all matching Distributors in the scheme."
          :items="distributorOptions"
          item-text="title"
          item-value="value"
          outlined
          multiple
        />

        <v-checkbox
          v-model="form.confirm_test"
          hide-details
          label="I created scheme from 'another' backend panel. And the distributor count is correct."
          :rules="[required]"
          class="mt-0"
        >
        </v-checkbox>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { useDistributor } from '@/composables';
import { required } from '@core/utils/validation';
import { } from '@mdi/js';
import { ref } from '@vue/composition-api';

export default {
  components: {
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const { distributorOptions } = useDistributor()

    const initialForm = {
      ...field('code', null),
      ...field('channel_distributor_ids', []),
    }

    const form = ref({
      ...initialForm,
      confirm_test: false,
    })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,
      distributorOptions,
    }
  },
}
</script>
